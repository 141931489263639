<template>
  <v-container py-0>
    <v-form ref="form">
      <v-layout px-3 pt-3>
        <v-flex xs7 sm7 md3 mr-4>
          <v-text-field
            v-model="login"
            :label="$t('users.login')"
            :success-messages="userExistsInIdentityMessage"
            :disabled="editMode"
            autofocus
            :loading="loginLoading"
            :rules="[
              () => rules.required(login, $t('users.login')),
              rules.login
            ]"
            @blur="checkUserExistsInIdentity"
            v-on:keyup.enter="$event.target.blur()"
          ></v-text-field>
        </v-flex>
        <v-flex xs7 sm7 md3 ml-4>
          <v-text-field
            v-model="email"
            :label="$t('users.email')"
            :rules="[
              () => rules.required(email, $t('users.email')),
              rules.email
            ]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout class="odd" px-3 pt-4 mb-4>
        <v-flex xs14 sm14 md3>
          <v-select
            v-model="selectedRole"
            :items="roles"
            item-text="roleName"
            item-value="roleId"
            :label="$t('users.role')"
            :rules="[() => rules.required(selectedRole, $t('users.role'))]"
            @input="getRoleRestrictions"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout px-3 pt-3>
        <v-flex>
          <RoleRestrictions :restrictions="roleRestrictions"></RoleRestrictions>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-spacer></v-spacer>
        <v-btn class="light-button" :to="{ name: 'users' }">
          {{ $t("global.cancel") }}
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="onConfirmClick">
          {{ acceptButtonValue }}
        </v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import RolesService from "../services/RolesService.js";
import UsersService from "../services/UsersService.js";
import RoleRestrictions from "../components/Restrictions";
import { mapState } from "vuex";
import { EmitSuccess } from "../event-bus";

export default {
  name: "NewUser",
  components: { RoleRestrictions },
  props: { userId: String },
  data() {
    return {
      login: "",
      email: "",
      roles: [],
      selectedRole: null,
      roleRestrictions: [],
      userExistsInIdentity: false,
      userExistsInIdentityMessage: "",
      loginLoading: false,
      loading: false,
      rules: {
        required: (val, label) =>
          !!val ||
          this.$t("global.required", {
            value: label
          }),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("validation.badEmailFormat");
        },
        login: value => {
          const pattern = /^[0-9a-zA-Z\\.\-\\]+$/;
          return pattern.test(value) || this.$t("validation.badLoginFormat");
        }
      }
    };
  },
  computed: {
    acceptButtonValue() {
      return this.$t(this.userId ? "global.save" : "global.add");
    },
    editMode() {
      return !!this.userId;
    }
  },
  watch: {
    login() {
      this.userExistsInIdentityMessage = "";
      this.userExistsInIdentity = false;
    }
  },
  mounted() {
    RolesService.GetRoles().then(data => {
      this.roles = data;
    });
    if (this.userId) {
      this.getUserDetails();
    }
  },
  methods: {
    _saveAction() {
      return this.editMode
        ? UsersService.UpdateUser(
            this.login,
            this.userId,
            this.email,
            this.selectedRole
          )
        : UsersService.CreateUser(this.login, this.email, this.selectedRole);
    },
    onConfirmClick() {
      if (!this.$refs["form"].validate()) {
        return;
      }
      this.loading = true;
      this._saveAction().then(result => {
        this.loading = false;
        if (result.isValid) {
          if (!this.userExistsInIdentity && !this.editMode) {
            EmitSuccess(this.$t("identityServer.sendPasswordWithEmail"));
          }
          this.$router.push({ name: "users" });
        }
      });
    },
    getRoleRestrictions() {
      return RolesService.GetRoleRestrictions(this.selectedRole).then(
        r => (this.roleRestrictions = r)
      );
    },
    checkUserExistsInIdentity() {
      if (!this.login.trim()) return;
      this.loginLoading = true;
      return UsersService.checkUserExistsInIdentity(this.login).then(
        response => {
          if (response.data) {
            this.userExistsInIdentity = true;
            this.userExistsInIdentityMessage = this.$t(
              "identityServer.userExistsInIdentity"
            );
          } else {
            this.userExistsInIdentity = false;
          }
          this.loginLoading = false;
        }
      );
    },
    getUserDetails() {
      UsersService.GetUserDetails(this.userId).then(data => {
        this.login = data.login;
        this.email = data.email;
        this.selectedRole = data.roleId;
        this.getRoleRestrictions();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  background-color: var(--v-secondary-base);
}
.bold {
  font-weight: bold;
}
.list-label {
  display: flex;
  padding-top: 15px;
}
.v-btn {
  text-transform: none;

  .light-button {
    color: var(--v-primary-base);
  }
}
</style>
